import { CommonModule, DOCUMENT, NgOptimizedImage, isPlatformBrowser } from '@angular/common';
import { Component, PLATFORM_ID, Inject, Input, ViewChild, ElementRef, ChangeDetectorRef, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { SlideshowBankComponent } from '@template/green/parts/slideshow-bank/slideshow-bank.component';
import { DataService } from '@componentpath/services/data.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { EncodeUriPipe, OrderByPipe, RemoveDashPipe, SlugNamePipe } from '@componentpath/pipes/slug-name.pipe';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { AuthService } from '@componentpath/services/auth.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  imports: [
    CommonModule,
    SlideshowBankComponent,
    MatExpansionModule,
    MatIconModule,
    SlugNamePipe,
    NgOptimizedImage,
    EncodeUriPipe,
    RouterModule,
    RemoveDashPipe,
    OrderByPipe
  ],
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('cardTitleFooter') cardTitleFooter!: ElementRef;
  @ViewChild('cardTitleTextFooter') cardTitleTextFooter!: ElementRef;
  @ViewChild('cardTitleFooterSmall') cardTitleFooterSmall!: ElementRef;
  @ViewChild('cardTitleTextFooterSmall') cardTitleTextFooterSmall!: ElementRef;

  subscription: Subscription = new Subscription();

  @Input() data: any;
  panelOpenState: boolean = false;
  bankList: Array<any> = [];
  providerList: Array<any> = [];
  footerJSONData: Array<any> = [];
  footerHtml: any = {};
  websiteJSONData: any = this.dataService.websiteJSONData;
  currentScreenSize: string = '';
  cardTitleWidth: any = 0;
  cardTitleTextWidth: any = 0;
  cardTitleSmallWidth: any = 0;
  cardTitleTextSmallWidth: any = 0;
  showReferral: boolean = true;
  addMorePadding: boolean = true;
  currentYear = new Date().getFullYear();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc: Document,
    private auth: AuthService,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver
  ) {
    this.bankList = this.dataService.bankRegisterJSONData['records'];
    // console.log(this.bankList)
    this.checkScreen();
  }

  checkScreen() {
    let displayNameMap = new Map([
      [Breakpoints.XSmall, 'xSmall'],
      ["(min-width: 600px) and (max-width: 799.98px)", 'smallOne'],
      ["(min-width: 800px) and (max-width: 959.98px)", 'smallTwo'],
      ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
    ]);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      "(min-width: 600px) and (max-width: 799.98px)",
      "(min-width: 800px) and (max-width: 959.98px)",
      "(min-width: 960px) and (max-width: 1365.98px)",
      "(min-width: 1366px)"
    ]).subscribe(result => {
      // console.log(result)
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  extractTitleAndBody(html: string): { title: string; body: string } {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const firstHeader = doc.querySelector('h1, h2, h3, h4, h5, h6');
    let title = '';
    if (firstHeader) {
      title = firstHeader.outerHTML || '';
      firstHeader.remove();
    }

    const body = doc.body.innerHTML;

    return { title, body };
  }

  subscriptionData() {
    this.subscription.add(
      this.auth.getLoginStatus.subscribe({
        next: (v) => {
          if (v && this.auth.userProfileData !== null) {
            console.log(this.dataService.referralRuleJSONData);
            // console.log(this.auth.userProfileData);
            for (const [key, value] of Object.entries(this.dataService.referralRuleJSONData)) {
              let val = value as any
              if (this.auth.userProfileData['group'] === val.group) {
                this.showReferral = true;
                break;
              } else {
                this.showReferral = false;
              }
            }
          }
          if (v === false) {
            if ((this.dataService.referralRuleJSONData as Array<any>).length === 0) {
              // console.log("empty")
              this.showReferral = false;
            }
          }
        },
      })
    );
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.subscriptionData();
      this.bankList = this.dataService.bankRegisterJSONData['records'];
      this.providerList = this.dataService.providerJSONData['records'];
      this.footerJSONData = this.dataService.footerJSONData['records'];
      if (this.activatedRoute.snapshot.url.length === 0){
        this.addMorePadding = true;
      } else {
        this.addMorePadding = false;
      }
      for (const value of this.footerJSONData) {
        if (
          this.activatedRoute.snapshot.url.length &&
          this.activatedRoute.snapshot.url[0].path === 'search' &&
          value.type === 'SL'
        ) {
          this.footerHtml = this.extractTitleAndBody(value.contents.id);
          break;
        } else if (this.activatedRoute.snapshot.url.length === 0) {
          if (value.type === 'HOMEPAGE') {
            this.footerHtml = this.extractTitleAndBody(value.contents.id);
            break;
          }
        } else {
          if (value.type === 'DEFAULT') {
            this.footerHtml = this.extractTitleAndBody(value.contents.id);

            break;
          }
        }
      }
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        // console.log(this.doc.querySelector("#cardTitleFooter")?.clientWidth)
        // console.log(this.cardTitleFooter.nativeElement.clientWidth)
        if (this.cardTitleFooter) {
          this.cardTitleWidth = this.doc.querySelector("#cardTitleFooter")?.clientWidth;
          this.cdr.detectChanges();
          // console.log(this.cardTitleWidth)
        }
        if (this.cardTitleTextFooter) {
          this.cardTitleTextWidth = this.cardTitleTextFooter.nativeElement.clientWidth;
          this.cdr.detectChanges();
          // console.log(this.cardTitleTextWidth)
        }
        if (this.cardTitleFooterSmall) {
          this.cardTitleSmallWidth = this.doc.querySelector("#cardTitleFooterSmall")?.clientWidth;
          this.cdr.detectChanges();
          // console.log(this.cardTitleWidth)
        }
        if (this.cardTitleTextFooterSmall) {
          this.cardTitleTextSmallWidth = this.cardTitleTextFooterSmall.nativeElement.clientWidth;
          this.cdr.detectChanges();
          // console.log(this.cardTitleTextWidth)
        }
      }, 1000);
    }

  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
      // window.removeEventListener('scroll', this.scroll, true);
    }
  }
}
