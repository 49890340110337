import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, CurrencyPipe, isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/green/header/header.component";
import { FooterComponent } from '@template/green/footer/footer.component';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { FormsModule } from '@angular/forms';
import { RequestService } from '@componentpath/services/request.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleQuestion, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faCircleChevronRight, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SwiperOptions } from 'swiper/types';
import { A11y, Grid, Mousewheel } from 'swiper/modules';
import { SwiperDirective } from '@componentpath/lib/swiper.directive';
import { DepositManualComponent } from '@template/green/parts/deposit-manual/deposit-manual.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DepositMobileComponent } from '@template/green/mobile/deposit-mobile/deposit-mobile.component';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';

@Component({
  selector: 'app-deposit',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, HeaderSimpleComponent, FooterComponent, FormsModule, FontAwesomeModule, NgbDropdownModule, CurrencyPipe, SwiperDirective, DepositManualComponent, DepositMobileComponent],
  templateUrl: './deposit.component.html',
  styleUrl: './deposit.component.scss'
})
export class DepositComponent {

  subscription: Subscription = new Subscription();

  faCircleQuestion = faCircleQuestion;
  faCircleChevronRight = faCircleChevronRight;
  faTrashCan = faTrashCan;
  faCircleInfo = faCircleInfo;

  currentOptionPayment: string = 'auto';
  currentMethodPayment: string = 'vacc';
  nextStepOption: boolean = false;
  showFormPaymentAuto: boolean = false;
  showFormPaymentManual: boolean = false;
  showConfirmPayment: boolean = false;
  hideSwitchMenu: boolean = false;
  inputAmount: any = null;
  currentNominal: number  = 0;
  userGroupData: Record<string, any> = {};
  bankCompanyList: Record<string, any> = {};
  depositConfig: Record<string,any> = {};
  loader: boolean = true;

  arrayNominal: Array<number>= [10000,20000,50000,100000,500000,1000000,5000000,10000000];

  public config: SwiperOptions = {
    init: true,
    modules: [A11y, Mousewheel, Grid],
    slidesPerView: 6,
    grabCursor: false,
    loop: false,
    mousewheel:  true,
    freeMode: true,
    
  };

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  currentScreenSize: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private request: RequestService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }

  checkScreen() {
    let displayNameMap = new Map([
      [Breakpoints.XSmall, 'xSmall'],
      ["(min-width: 600px) and (max-width: 799.98px)", 'smallOne'],
      ["(min-width: 800px) and (max-width: 959.98px)", 'smallTwo'],
      ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
    ]);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      "(min-width: 600px) and (max-width: 799.98px)",
      "(min-width: 800px) and (max-width: 959.98px)",
      "(min-width: 960px) and (max-width: 1365.98px)",
      "(min-width: 1366px)"
    ]).subscribe(result => {
      // console.log(result)
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  nextButtonOption(){
    if(this.currentOptionPayment === 'auto'){
      if(this.depositConfig['virtual_account'] && this.depositConfig['qris']){
        this.nextStepOption = true;
      } else {
        this.showFormPaymentAuto = true;
      }
    } else {
      this.showFormPaymentManual = true;
    }
  }

  nextButtonMethod(){
    this.showFormPaymentAuto = true;
  }

  selectOptionPayment(val:string){
    this.currentOptionPayment = val;
    this.showConfirmPayment = false;
  }

  selectMethodPayment(val:string){
    this.currentMethodPayment = val;
    this.showConfirmPayment = false;
  }

  setNominalChip(val:number){
    this.currentNominal = val;
    this.inputAmount = this.currentNominal;
  }

  updateValueNominal(e: any){
    let rawNominal = e.split('.').join("");
    this.inputAmount = parseInt(rawNominal);
    this.setNominalChip(this.inputAmount);
  }

  confirmDeposit(){
    this.showConfirmPayment = true;
  }

  getBankCompany(){
    this.request.getBankCompanyList(this.auth.sessionValue).subscribe({
      next: (val) => {
        // console.log(val);
        if(val['code'] === 200){
          if (val['data'][this.userGroupData['rank']] !== undefined){
            this.bankCompanyList = val['data'][this.userGroupData['rank']];
          } else {
            this.bankCompanyList = val['data'][1];
          }
          // console.log(this.bankCompanyList);
        }
      },
      error: (err) =>{
        console.log(err)
      },
      complete:()=>{}
    })
  }

  subscriptionData() {
    if (this.currentScreenSize === 'desktop'){
      this.subscription.add(this.route.queryParams.subscribe((params) => {
        if (params['p'] === '2') {
          this.hideSwitchMenu = true;
          if(!this.showFormPaymentAuto && !this.showFormPaymentManual){
            this.router.navigate(['/deposit'])
          }
        } else {
          this.hideSwitchMenu = false;
          if (!this.showFormPaymentAuto && !this.showFormPaymentManual){
            this.router.navigate(['/deposit'])
          }
        }
      }));
    }
  }

  getDepositConfig(){
    this.request.getDepositConfig(this.auth.sessionValue).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          // console.log(val);
          this.depositConfig = val['data'] as Record<string, any>;
          // this.depositConfig['status'] = true;
          // this.depositConfig['qris'] = true;
          // this.depositConfig['virtual_account'] = true;
          if(this.depositConfig['status'] === false){
            this.showFormPaymentManual = true; 
            this.currentOptionPayment = "manual";
          }
          if ((this.depositConfig['virtual_account'] && !this.depositConfig['qris'])){
            this.currentMethodPayment = 'vacc';
            
          } 
          if (!this.depositConfig['virtual_account'] && this.depositConfig['qris']){
            this.currentMethodPayment = 'qris'

          }
          this.loader =false;
        }
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => { }
    })
  }

  // setDepositAuto(){
  //   this.request.setTransactionAutoDeposit(this.auth.sessionValue, 'qris', '25000','6636f797efadf956770a21bc').subscribe({
  //     next: (val) => {
  //       if (val['code'] === 200) {
  //         console.log(val);
  //       }
  //     },
  //       error: (err) => {
  //         console.log(err)
  //       },
  //         complete: () => { }
  //   })
  // }
  
  // checkActiveDepositAuto(){
  //   this.request.checkActiveTransactionAuto(this.auth.sessionValue).subscribe({
  //     next: (val) => {
  //       if (val['code'] === 200) {
  //         console.log(val);
  //         if(val.data){

  //         }else {
  //           this.setDepositAuto();
  //         }
  //       }
  //     },
  //       error: (err) => {
  //         console.log(err)
  //       },
  //         complete: () => { }
  //   })
  // }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getBankCompany();
      this.subscriptionData();
      this.getDepositConfig();
      // this.checkActiveDepositAuto();
      // console.log(this.auth.userGorupData);
      this.userGroupData = this.auth.userGorupData;
      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}
